<template>
  <v-app>
    <NavBar></NavBar>
    <Carousel></Carousel>
    <Objective></Objective>
    <Programs></Programs>
    <Cases></Cases>
    <Banner></Banner>
    <Footer></Footer>
    <Snackbard></Snackbard>
  </v-app>
</template>

<script>
import Banner from '@/components/Banner.vue';
import Carousel from '@/components/Carousel.vue';
import Cases from '@/components/Cases.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import Objective from '@/components/Objective.vue';
import Programs from '@/components/Programs.vue';
import Snackbard from '@/components/Snackbar.vue';
import 'animate.css/animate.css';

export default {
  name: 'Home',
  components: {
    Banner,
    Carousel,
    Cases,
    Footer,
    NavBar,
    Objective,
    Programs,
    Snackbard,
  },
  data: () => ({
    // color: "",
    // flat: null,
  }),
};
</script>
<style>
.divider {
  background: #930b27;
  height: 7px;
  margin-bottom: 1rem;
  width: 70px;
}
</style>
