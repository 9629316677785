<template>
  <v-app>
    <NavBar></NavBar>
    <div
      class="about-us__header d-flex flex-column justify-center align-center"
    >
      <div></div>
      <p
        class="text-h4 text-center text-uppercase font-weight-bold pb-3  white--text"
        data-aos="fade-up"
      >
        Acerca de nosotros
      </p>
      <!-- <p class="header__subtitle text-justify white--text"
            data-aos="fade-down"
          >
            Conoce un poco más acerca del Instituto Ténico Ricaldone
          </p> -->
      <v-btn
        class="ma-2"
        outlined
        large
        fab
        color="white"
        href="#historia"
        data-aos="fade-down"
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <div id="historia" class="about-us__historia pa-6">
      <p
        class="historia__title text-h4 text-center text-uppercase font-weight-black pb-5"
        data-aos="fade-up"
      >
        Nuestra historia
      </p>
      <p
        class="historia__subtitle text-center black--text"
        data-aos="fade-down"
      >
        Breve video sobre nuestra historia salesiana
      </p>
      <v-row class="d-flex align-center justify-center">
        <v-col
          cols="12"
          md="8"
          data-aos="fade-down"
          class="d-flex flex-column justify-center align-center"
        >
          <div class="iframe__container">
            <iframe
              class="iframe--responsive"
              src="https://www.youtube.com/embed/F9Td3XiPCj0"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="about-us__mision  pa-6">
      <div class="row-container">
      <v-row
        class="d-flex flex-md-row flex-sm-column-reverse fill-height pa-6 py-8"
        max-width="1368"
      >
        <v-col cols="12" md="6" sm="12" class="pa-5" data-aos="fade-right">
          <v-img
            lazy-src="../assets/mision.jpg"
            max-height="300"
            max-width="550"
            src="../assets/mision.jpg"
            class="img--rounded img-responsive"
          ></v-img>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="12"
          class="d-flex flex-column justify-center align-center"
        >
          <div data-aos="fade-right">
            <p
              class="mision__title text-h4 text-center white--text text-uppercase font-weight-bold pb-5"
            >
              Nuestra misión
            </p>
            <p class="mision__text text-justify white--text">
              Somos una comunidad de salesianos consagrados que,
              corresponsablemente con laicos, nos dedicamos a educar y evangelizar
              a los niños, adolescentes, jóvenes y sus familias, priorizando a los
              menos favorecidos con el método educativo de Don Bosco,
              ofreciéndoles una propuesta que los prepare a integrarse al mundo de
              la cultura y del trabajo digno y a ser miembros vivos de la sociedad
              y de la iglesia.
            </p>
          </div>
        </v-col>
      </v-row>
      </div>
    </div>
    <div class="about-us__vision pa-6">
      <div class="row-container">
      <v-row
        class="d-flex flex-md-row flex-sm-column-reverse fill-height pa-6 py-8"
      >
        <v-col
          cols="12"
          md="6"
          sm="12"
          class="d-flex flex-column justify-center align-center"
        >
          <div data-aos="fade-right">
            <p
              class="vision__title text-h4 text-center text-uppercase font-weight-black pb-5"
            >
              Nuestra visión
            </p>
            <p
              class="vision__text text-justify black--text align-self-start ml-2"
            >
              Al año 2022 ser identificados en Centroamérica por:
            </p>
            <ul class="align-self-start">
              <li class="mb-3">
                Comunidades de consagrados que testimonian la radicalidad
                evangélica
              </li>
              <li class="mb-3">
                La significatividad de sus obras, en atención a las necesidades de
                los niños, adolescentes y jóvenes en: prevención, educación y
                evangelización
              </li>
              <li class="mb-3">
                La corresponsabilidad entre salesianos y laicos
              </li>
              <li class="mb-3">
                Una oferta de servicios educativo-pastorales pertinentes,
                flexibles y de calidad
              </li>
            </ul>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="12"
          class="pa-5 d-flex justify-center align-center"
          data-aos="fade-right"
        >
          <v-img
            lazy-src="../assets/vision.jpg"
            max-height="300"
            max-width="550"
            src="../assets/vision.jpg"
            class="img--rounded img-responsive"
          ></v-img>
        </v-col>
      </v-row>
      </div>
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'About',
  components: {
    NavBar,
    Footer,
  },
  data: () => ({}),
};
// Mobile Height https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
// Primero obtenemos la altura de la ventana gráfica y la multiplicamos por 1% para obtener un valor para una unidad vh
let vh = window.innerHeight * 0.01;
// Luego establecemos el valor en la propiedad personalizada --vh en la raíz del documento
document.documentElement.style.setProperty('--vh', `${vh}px`);
</script>

<style>
html {
  scroll-behavior: smooth;
}
.about-us__header {
  height: 100vh;
}

.about-us__historia {
  max-height: 500px;
  overflow: hidden;
}

.about-us__header {
  background-attachment: fixed;
  background-image: url(../assets/history-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.about-us__header:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.historia__title,
.mision__title,
.vision__title {
  position: relative;
  color: #212121;
  font-size: 1.8rem;
}

.historia__title::after,
.mision__title::after,
.vision__title::after {
  background: #930b27;
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 40px;
  transform: translateX(-50%);
}

.mision__title::after {
  background: #f5be00;
}

.row-container{
  max-width: 1368px;
  margin: 0 auto;
}

.about-us__mision {
  background: #930b27;
}

.about-us__mision,
.about-us__vision {
  min-height: 400px;
}

.img-responsive {
  width: 100%;
  height: auto;
}
.iframe__container {
  position: relative;
  width: 100%;
  max-width: 560px;
  max-height: 315px;
  overflow: hidden;
  padding-top: 75%; /* 4:3 Aspect Ratio */
}

.iframe--responsive {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  max-width: 560px;
  max-height: 315px;
}
</style>
