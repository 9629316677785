<template>
  <div>
    <!-- Drawer -->
    <v-navigation-drawer v-model="drawer" app temporary dark right>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/logo.png" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title"
              >Instituto Técnico</v-list-item-title
            >
            <v-list-item-subtitle>Ricaldone</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item v-for="(item, i) in items" :key="i" link :to="item.href">
          <v-list-item-icon class="justify-center">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              item.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click.stop="dialog = true">
          <v-list-item-icon class="justify-center">
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">
              Iniciar Sesión
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- AppBar -->
    <v-app-bar flat dark>
      <router-link to="/">
        <v-img src="@/assets/logo-ricaldone.png" width="200"></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      >
      </v-app-bar-nav-icon>
      <div v-else>
        <router-link to="/" class="text-decoration-none">
          <v-btn text class="mr-2">
            <span>Inicio</span>
          </v-btn>
        </router-link>
        <router-link to="/donation" class="text-decoration-none">
          <v-btn text class="mr-2">
            <span>Donación</span>
          </v-btn>
        </router-link>
        <v-btn href="/programa-becas-2024.pdf" download target="_blank" text class="mr-2">
          <span>Programa de becas</span>
        </v-btn>
        <router-link to="/about" class="text-decoration-none">
          <v-btn text class="mr-2">
            <span>Acerca de nosotros</span>
          </v-btn>
        </router-link>
        <v-btn rounded outlined text @click.stop="dialog = true" class="ml-2">
          <span>Iniciar Sesión</span>
        </v-btn>
      </div>
    </v-app-bar>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-text class="pa-0">
          <v-tabs v-model="tab" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              Iniciar Sesión
              <v-icon>mdi-login</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              Recuperar Contraseña
              <v-icon>mdi-account-plus</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" :value="'tab-' + 1">
              <v-form>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col class="12">
                        <v-text-field
                          label="Correo electrónico"
                          type="text"
                          outlined
                          hide-details
                          v-model="loginForm.email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="12">
                        <v-text-field
                          label="Contraseña"
                          outlined
                          hide-details
                          v-model="loginForm.password"
                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPass ? 'text' : 'password'"
                          @click:append="showPass = !showPass"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p class="mb-0 black--text text-sm-justify">
                          El <i>inicio de sesión</i> solo es para los donadores
                          frecuentes, si desea acceder a esta plataforma, cuando
                          realice una donación debe de activar la casilla que
                          desea ser donante frecuente, en el último paso de
                          confirmación.
                        </p>
                        <router-link to="/donation"
                          >Por favor, haz clic aquí</router-link
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn
                      text
                      color="primary"
                      class="ml-auto"
                      @click="login"
                      :loading="loading"
                    >
                      Iniciar Sesión
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-tab-item>
            <v-tab-item :key="2" :value="'tab-' + 2">
              <v-card flat>
                <v-card-text class="text-center">
                  <p class="text-h6">
                    ¿Has olvidado tu contraseña?
                  </p>
                  <p class="body2">
                    ¡Nos pasa a los mejores! Ingrese su dirección de correo
                    electrónico para poder acceder nuevamente a su cuenta
                  </p>
                  <v-text-field
                    label="Correo electrónico"
                    placeholder="correo@dominio.com"
                    type="email"
                    outlined
                    hide-details
                    v-model="recoverForm.email"
                  ></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    class="ml-auto"
                    @click="recoverPassword"
                    :loading="loadingReset"
                  >
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Snackbard />
    <!-- Modal Donor -->
    <DonorModal ref="mdlUser"></DonorModal>
  </div>
</template>

<script>
import DonorModal from '@/views/Donor';
import { mapActions, mapGetters } from 'vuex';
import Snackbard from '@/components/Snackbar';

export default {
  name: 'NavBar',
  components: {
    DonorModal,
    Snackbard,
  },
  data: () => ({
    loading: false,
    loadingReset: false,
    tab: null,
    dialog: false,
    drawer: null,
    isXs: false,
    color: '',
    showPass: false,
    items: [
      {
        icon: 'mdi-home-outline',
        text: 'Inicio',
        href: '/',
      },
      {
        icon: 'mdi-gift-outline',
        text: 'Donación',
        href: '/donation',
      },
      {
        icon: 'mdi-alert-circle-outline',
        text: 'Acerca de nosotros',
        href: '/about',
      },
    ],
    loginForm: {},
    recoverForm: {},
  }),
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {
      passive: true,
    });
  },
  methods: {
    ...mapActions({
      getDonor: 'Donor/getDonor',
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    login: async function() {
      this.loading = true;
      await this.$http
        .post(this.api + 'login', this.loginForm)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          if (data.response) {
            sessionStorage.setItem('donor_frequent_token', data.token);
            this.$http.defaults.headers['Authorization'] = data.token;
            this.loginForm = {};
            this.$router.push('/donor/home');
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        })
        .then(() => {
          this.loading = false;
        });
    },

    recoverPassword: function() {
      this.loadingReset = true;
      this.$http
        .post(this.api + '/recover_password', this.recoverForm)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
        })
        .catch((err) => {
          console.error('Error ' + err);
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        })
        .then(() => {
          this.loadingReset = false;
        });
      this.recoverForm = {};
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    toDonation() {
      this.$router.push('/donation');
    },
    toHome() {
      this.$router.push('/');
    },
    modalUser() {
      this.$refs.mdlUser.changeModal();
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      token: 'Donor/getToken',
      user: 'Donor/getDonor',
    }),
  },
};
</script>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>
