export default {
  namespaced: true,
  state: {
    token: '',
    donor: {},
    authentication: false,
  },
  getters: {
    getToken: (state) => state.token,
    getDonor: (state) => state.donor,
    getAuthentication: (state) => state.authentication
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setDonor(state, donor) {
      state.donor = donor;
    },
    setAuthentication(state, authentication) {
      state.authentication = authentication;
    },
  },
  actions: {
    getDonor: async function({
      commit
    }, vm) {
      const {
        data
      } = await vm.$http.get(vm.api + 'profile/my_info');
      if (data.photo != null) {
        data.file_photo = data.photo;
        data.photo = null;
      }
      if (data.response == false) {
        commit('setAuthentication', false);
      }
      commit('setDonor', data);
      commit('setAuthentication', true);
    }
  },
};