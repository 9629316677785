<template>
  <div class="my-5">
    <v-card elevation="1">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card-title>Nombre del Grupo: {{ group.group }}</v-card-title>
          <v-card-subtitle class="py-0"
            >Descripción del Grupo: {{ group.description }}</v-card-subtitle
          >
          <v-card-subtitle class="py-0"
            >Cantidad de Donadores:
            {{ group.total_frequent_donors }}</v-card-subtitle
          >
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <div class="d-flex flex-column pa-5 align-end">
            <p class="title mb-0">Meta de Donación</p>
            <p class="subtitle mb-1">
              ${{ parseFloat(group.goal).toFixed(2) }}
            </p>
            <v-progress-linear
              color="red accent-4"
              height="25"
              :value="(group.collected / group.goal) * 100"
              rounded
            >
              <strong class="white--text"
                >${{ group.collected }} / ${{ group.goal }}</strong
              >
            </v-progress-linear>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="data.headers"
          :items="data.data"
          :items-per-page="5"
          :loading="data.load"
          :search="search"
          sort-by="email"
          class="elevation-0 border-solid"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="text-h5">
                Donadores del grupo - {{ group.group }}
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                label="Buscar..."
                v-model="search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:[`item.estado_donador`]="{ item }">
            <v-chip
              v-if="item.estado_donador == 1"
              color="red"
              text-color="white"
            >
              Atrasado
            </v-chip>
            <v-chip
              v-else-if="item.estado_donador == 0"
              color="green"
              text-color="white"
            >
              Al día
            </v-chip>
            <v-chip v-else color="secondary" text-color="white">
              Desconocido
            </v-chip>
          </template>
          <template v-slot:[`item.total_donated_year`]="{ item }">
            $
            {{
              item.total_donated_year != null
                ? parseFloat(item.total_donated_year).toFixed(2)
                : "0.00"
            }}
          </template>
          <template v-slot:[`item.goal_group`]="{ item }">
            $
            {{
              item.goal_group != null
                ? parseFloat(item.goal_group).toFixed(2)
                : "0.00"
            }}
          </template>
          <!-- Actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="donorHistorial(item)"
                  color="deep-orange lighten-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-hand-heart
                </v-icon>
              </template>
              <span>Historial de Donaciones</span>
            </v-tooltip>
          </template>
          <!-- Si no carga la data -->
          <template v-slot:no-data>
            <v-btn color="primary"> Reiniciar </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DonorDonations
      ref="mdlHistorial"
      v-bind:donor="historial"
    ></DonorDonations>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DonorDonations from "./DonorDonations.vue";
export default {
  name: "GroupDonation",
  components: {
    DonorDonations,
  },
  data: () => ({
    data: {
      load: false,
      headers: [
        { text: "Nombre de Donador", value: "name" },
        { text: "Correo electrónico", value: "email" },
        { text: "Teléfono", value: "phone" },
        { text: "Última Donación", value: "last_transaction_date" },
        { text: "Promesa de donación anual", value: "goal_group" },
        { text: "Total donado al año", value: "total_donated_year" },
        // { text: 'Estado de Donación', value: 'estado_donador' },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      data: [],
    },
    historial: {},
    search: "",
  }),
  props: {
    group: {},
  },
  mounted() {
    this.getDonorsGroup();
  },
  methods: {
    ...mapActions({
      getDonor: "Donor/getDonor",
      openSnackbar: "Snackbar/openSnackbar",
    }),

    getDonorsGroup: async function () {
      this.data.load = true;
      await this.$http
        .get(this.api + "donor_group/all/donors_group/" + this.group.id_group)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    donorHistorial(item) {
      this.historial = {};
      this.historial = item;
      this.modalHistorial();
    },

    modalHistorial() {
      this.$refs.mdlHistorial.changeModal();
    },

    changeModal: function () {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      token: "Donor/getToken",
      user: "Donor/getDonor",
    }),
  },
  watch: {
    group() {
      this.getDonorsGroup();
    },
  },
};
</script>

<style></style>
