<template>
  <v-app>
    <NavBar></NavBar>
    <div class="donation donation__bg py-10">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div>
              <p class="text-h4 text-center white--text font-weight-black text-uppercase" data-aos="fade-up">Formulario de Donación</p>
              <div class="divider mx-auto" data-aos="fade-up"></div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="workInProgress">
          <v-col>
            <v-card class="py-5 px-7 d-flex flex-column justify-center align-center" style="height: 50vh;">
              <h1>Formulario de donaciones en mantenimiento</h1>
              <v-icon x-large color="red">
                mdi-wrench-clock
              </v-icon>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="d-flex justify-center">
          <v-col sm="12" md="8">
            <FormDonation></FormDonation>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>
import FormDonation from '@/components/FormDonation.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'Donation',
  components: {
    FormDonation,
    Footer,
    NavBar,
  },
  data: () => ({
    color: '',
    workInProgress: false,
  }),
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  methods: {
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.color = '#212121';
      } else {
        this.color = '#212121';
      }
    },
  },
};
</script>

<style scoped>
.donation {
  margin: 0 auto;
  width: 100%;
}

.donation__bg{
  background: url(../assets/comedor.jpeg);
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.donation__bg:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

@media screen and (max-width: 960px) {
  .donation--image {
    margin: 0 auto;
    height: 400px;
  }
}
</style>
