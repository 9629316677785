<template>
  <div id="custom-turnstile" ref="turnstile"></div>
</template>

<script>
export default {
  name: "Turnstile",
  data() {
    return {
      sitekey: "0x4AAAAAAAPlaMJAi94P1Mfz",
      turnstileInstance: null,
      scriptLoaded: false,
    };
  },
  mounted() {
    this.loadTurnstileScript();
  },
  beforeDestroy() {
    if (this.turnstileInstance && window.turnstile) {
      window.turnstile.remove(this.turnstileInstance);
    }
  },
  methods: {
    loadTurnstileScript() {
      if (window.turnstile) {
        this.scriptLoaded = true;
        this.initTurnstile();
      } else if (!this.scriptLoaded) {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://challenges.cloudflare.com/turnstile/v0/api.js');
        script.setAttribute('async', true);
        script.setAttribute('defer', true);

        script.onload = () => {
          this.scriptLoaded = true;
          this.initTurnstile();
        };

        document.head.appendChild(script);
      }
    },
    initTurnstile() {
      if (window.turnstile && this.$refs.turnstile && !this.turnstileInstance) {
        this.turnstileInstance = window.turnstile.render(this.$refs.turnstile, {
          sitekey: this.sitekey,
          callback: (token) => {
            this.$emit('verified', token);
          },
        });
      }
    },
    reload() {
      if (this.turnstileInstance && window.turnstile) {
        window.turnstile.reset(this.turnstileInstance);
      } else {
        this.loadTurnstileScript();
      }
    },
  }
};
</script>