<template>
  <v-col class="text-center" cols="12">
    {{ new Date().getFullYear() }} - <strong>Instituto Técnico Ricaldone</strong>
  </v-col>
</template>

<script>
export default {
  name: 'Copyright',
}
</script>
