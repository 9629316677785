<template>
  <div class="cases" id="case_select">
    <p
      class="text-h4 title-cases text-center text-uppercase font-weight-black pb-5 my-6"
      data-aos="fade-up"
    >
      Casos de Ayuda
    </p>
    <div class="my-3"></div>
    <v-row
      class="black--text d-flex align-center justify-center"
      data-aos="fade-down"
    >
      <v-col cols="12" sm="12" md="6">
        <iframe
          width="100%"
          height="400"
          :src="'https://www.youtube.com/embed/' + current_case.url"
        ></iframe>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6">
          {{ current_case.title }}
        </p>
        <div class="divider"></div>
        <p class="body-1 text-justify">
          {{ current_case.text }}
        </p>
      </v-col>
    </v-row>
    <v-row class="slide-cases my-5 my-md-7" data-aos="fade-right">
      <v-col cols="12" md="12">
        <VueSlickCarousel v-if="data.slides.length > 0" v-bind="settings">
          <div
            v-for="(slide, index) in data.slides"
            :key="index"
            class="d-flex justify-center align-center img--slider"
          >
            <v-img
              contain
              :lazy-src="img + 'success/' + slide.image"
              max-height="150"
              max-width="300"
              :src="img + 'success/' + slide.image"
              @click="clickSlider(slide)"
            />
          </div>
        </VueSlickCarousel>
      </v-col>
    </v-row>
    <v-row class="slide-cases-mobile">
      <v-col cols="12" md="12">
        <VueSlickCarousel v-if="data.slides.length > 0" v-bind="settingsMobile">
          <div
            v-for="(slide, index) in data.slides"
            :key="index"
            class="d-flex justify-center align-center"
          >
            <v-img
              contain
              :lazy-src="img + 'success/' + slide.image"
              max-height="150"
              max-width="200"
              :src="img + 'success/' + slide.image"
              @click="clickSlider(slide)"
              href="#case_select"
            />
          </div>
        </VueSlickCarousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { mapGetters } from 'vuex';
export default {
  name: 'Cases',
  components: {
    VueSlickCarousel,
  },
  data: () => ({
    data: {
      load: true,
      slides: [],
    },
    current_case: {},
    settings: {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      cssEase: 'linear',
    },
    settingsMobile: {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      vertical: false,
      verticalSwiping: true,
    },
  }),
  mounted() {
    this.getCases();
  },
  methods: {
    getCases: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'success/all')
        .then(({ data }) => {
          if (data.length > 0) {
            data.forEach((item) => {
              this.setLinkEmbed(item);
            });
            this.current_case = data[0];
          }
          this.data.slides = data;
        })
        .catch((err) => {
          console.error('Error al obtener Success ' + err);
        })
        .then(() => {
          this.data.load = false;
        });
    },
    setLinkEmbed: function(val) {
      let url_arr = val.link.split('v=');
      if (url_arr[1]) {
        val.url = url_arr[1];
      }
    },
    clickSlider: function(slide) {
      this.current_case = {};
      this.current_case = slide;
      document.getElementById('case_select').scrollIntoView();
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
    }),
  },
};
</script>

<style>
.title-cases {
  position: relative;
  color: #212121;
  font-size: 1.8rem;
}

.title-cases:after {
  background: #930b27;
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  left: calc(50% - 20px);
  bottom: 0;
  width: 5%;
}

.cases {
  font-weight: 300;
  margin: 0 auto;
  padding: 30px;
  width: 90%;
}
.slide-cases {
  display: flex;
}
.slide-cases-mobile {
  display: none;
}

.slick-prev:before,
.slick-next:before {
  color: #ababab !important;
  font-size: 30px !important;
}
.slick-prev {
  left: -35px !important;
}
.slick-next {
  right: -35px !important ;
}
.slick-slide {
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
}

.img--slider {
  cursor: pointer;
  position: relative;
  transition: all ease-in 0.5s;
}

.img--slider::after {
  content: 'VER MÁS';
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all ease-in 0.5s;
  font-size: 18px;
  color: #000;
  background: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: 600;
  transform: translate(-50%, -50%);
}

.img--slider:hover {
  filter: grayscale(1);
}

.img--slider:hover::after {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .cases {
    width: 100%;
  }
  .slide-cases {
    display: none;
  }
  .slide-cases-mobile {
    display: block;
    width: 100%;
  }
}
</style>
