import Vue from "vue";
import Axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTheMask from "vue-the-mask";
import vuetify from "./plugins/vuetify";
import * as VeeValidate from "vee-validate";
import AOS from "aos";
import "aos/dist/aos.css";

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.use(VueTheMask);
Vue.use(VeeValidate);

AOS.init({
  duration: 1000,
});

Vue.mixin({
  data() {
    return {};
  },
  methods: {
    searchInArray: (arr, search) => arr.indexOf(search) >= 0,
    toFormData: (obj) => {
      var form_data = new FormData();
      for (var key in obj) {
        form_data.append(key, obj[key]);
      }
      return form_data;
    },
    toUSDFormat(str) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(str);
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
