<template>
  <div class="bg-banner">
    <v-container>
      <v-row>
        <v-col cols="12" class="banner">
          <div class="py-5">
            <div class="banner--content">
              <div data-aos="fade-up">
                <p class="text-h5 white--text text-uppercase font-weight-black">
                  ¿Deseas ser un donante frecuente?
                </p>
                <hr class="divider" />
                <p class="body-1 white--text">
                  Al realizar una donación existe la opción de registrarse como
                  donador frecuente, dicha funcionalidad permite iniciar sesión
                  en la plataforma, realizar donaciones rapidamente y pertenecer
                  a un grupo de donación para apoyar en conjunto los programas
                  sociales.
                </p>
                <router-link to="/donation" class="text-decoration-none">
                  <v-btn large class="btn-register">
                    Registrarme
                  </v-btn>
                </router-link>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Banner',
};
</script>

<style scoped>
.bg-banner {
  background-attachment: fixed;
  background-image: url(../assets/comedor.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 10;
}

.bg-banner:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  min-height: 400px;
  text-align: center;
}

.banner--content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  width: 60%;
}

hr.divider {
  background: white;
  height: 7px;
  width: 100px;
  margin: 1.5rem auto;
}

.btn-register {
  color: #fff;
  background: #930b27 !important;
}

@media screen and (max-width: 600px) {
  .banner--content {
    width: 100%;
  }

  p {
    padding: 1rem 0;
  }

  button {
    margin-bottom: 1rem;
  }
}
</style>
