import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import DashboardDonor from "@/views/DashboardDonor.vue";
import Donation from "@/views/Donation.vue";
import Home from "../views/Home.vue";
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/private/:token?",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "home",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "accounting/groups",
        component: () => import("@/views/AccountingGroup"),
      },
      {
        path: "accounting/groups/:id_group",
        component: () => import("@/views/AccountingGroup/Group.vue"),
      },
      {
        path: "accounting/donations",
        component: () => import("@/views/AccountingDonations"),
      },
      {
        path: "donor",
        component: () => import("@/views/DonorFrequent"),
      },
      {
        path: "coordinator",
        component: () => import("@/views/Coordinator"),
      },
      {
        path: "legal",
        component: () => import("@/views/Legal"),
      },
      {
        path: "donation-type",
        component: () => import("@/views/DonationType"),
      },
      {
        path: "country",
        component: () => import("@/views/Country"),
      },
      {
        path: "donation-group",
        component: () => import("@/views/DonationGroup"),
      },
      {
        path: "article/objective",
        component: () => import("@/views/Objective"),
      },
      {
        path: "article/header",
        component: () => import("@/views/Header"),
      },
      {
        path: "article/program",
        component: () => import("@/views/Program"),
      },
      {
        path: "article/case",
        component: () => import("@/views/Case"),
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/donation",
    name: "Donation",
    component: Donation,
  },
  {
    path: "/donor/:token?",
    name: "DashboardDonor",
    component: DashboardDonor,
    children: [
      {
        path: "home",
        component: () => import("@/views/Home/Donor.vue"),
      },
      {
        path: "profile",
        component: () => import("@/views/Donor/Profile.vue"),
      },
      {
        path: "donation",
        component: () => import("@/views/Donor/Donation.vue"),
      },
      {
        path: "my-donations",
        component: () => import("@/views/Donor/Donor.vue"),
      },
      {
        path: "my-groups",
        component: () => import("@/views/Donor/Coordinator.vue"),
      },
      {
        path: "my-donations",
        component: () => import("@/views/Donor/Donation.vue"),
      },
      {
        path: "frecuent-donors",
        component: () => import("@/views/Donor/FrecuentDonors.vue"),
      },
      {
        path: "transactions",
        component: () => import("@/views/Donor/Transactions.vue"),
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
