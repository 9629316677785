<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Detalle de donación</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" class="font-weight-bold body-1">
              <p class="text-h6 font-weight-black">
                Datos de la donación
              </p>
              <p>
                Cantidad: $<span class="font-weight-medium">{{ donation.transaction_amount }}</span>
              </p>
              <p>
                Fecha y Hora de donación: <span class="font-weight-medium">{{ donation.transaction_time_format }}</span>
              </p>
              <p>
                Donación dirigida a: <span class="font-weight-medium">{{ donation.donation_type }}</span>
              </p>
              <p>
                N° de Autorización: <span class="font-weight-medium">{{ donation.transaction_authorization}}</span>
              </p>
              <p>
                N° de Referencia: <span class="font-weight-medium">{{ donation.transaction_reference}}</span>
              </p>
              <p v-if="donation.voucher == 0">
                Comprobante: <span class="font-weight-medium">No pidió comprobante</span>
              </p>
              <p v-if="donation.voucher == 1">
                Comprobante: <span class="font-weight-medium">Pidió comprobante</span>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DonationDetail',
  data() {
    return {
      dialog: false
    }
  },
  props: {
    donation: {}
  },
  mounted() { },
  methods: {
    changeModal: function(){
      this.dialog = !this.dialog;
    }
  },
}
</script>

<style>

</style>