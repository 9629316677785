var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-5"},[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card-title',[_vm._v("Nombre del Grupo: "+_vm._s(_vm.group.group))]),_c('v-card-subtitle',{staticClass:"py-0"},[_vm._v("Descripción del Grupo: "+_vm._s(_vm.group.description))]),_c('v-card-subtitle',{staticClass:"py-0"},[_vm._v("Cantidad de Donadores: "+_vm._s(_vm.group.total_frequent_donors))])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-column pa-5 align-end"},[_c('p',{staticClass:"title mb-0"},[_vm._v("Meta de Donación")]),_c('p',{staticClass:"subtitle mb-1"},[_vm._v(" $"+_vm._s(parseFloat(_vm.group.goal).toFixed(2))+" ")]),_c('v-progress-linear',{attrs:{"color":"red accent-4","height":"25","value":(_vm.group.collected / _vm.group.goal) * 100,"rounded":""}},[_c('strong',{staticClass:"white--text"},[_vm._v("$"+_vm._s(_vm.group.collected)+" / $"+_vm._s(_vm.group.goal))])])],1)])],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 border-solid",attrs:{"headers":_vm.data.headers,"items":_vm.data.data,"items-per-page":5,"loading":_vm.data.load,"search":_vm.search,"sort-by":"email"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Donadores del grupo - "+_vm._s(_vm.group.group)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Buscar...","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.estado_donador",fn:function(ref){
var item = ref.item;
return [(item.estado_donador == 1)?_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v(" Atrasado ")]):(item.estado_donador == 0)?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" Al día ")]):_c('v-chip',{attrs:{"color":"secondary","text-color":"white"}},[_vm._v(" Desconocido ")])]}},{key:"item.total_donated_year",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.total_donated_year != null ? parseFloat(item.total_donated_year).toFixed(2) : "0.00")+" ")]}},{key:"item.goal_group",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.goal_group != null ? parseFloat(item.goal_group).toFixed(2) : "0.00")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"deep-orange lighten-1"},on:{"click":function($event){return _vm.donorHistorial(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-hand-heart ")])]}}],null,true)},[_c('span',[_vm._v("Historial de Donaciones")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reiniciar ")])]},proxy:true}],null,true)})],1)],1),_c('DonorDonations',{ref:"mdlHistorial",attrs:{"donor":_vm.historial}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }