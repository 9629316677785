<template>
  <div class="programs-carousel white--text">
    <div>
      <div>
        <p
          class="programs__title white--text text-h4 text-center pb-3 mb-4 font-weight-black text-uppercase"
          data-aos="fade-up"
        >
          Programas de ayuda
        </p>
      </div>
    <VueSlickCarousel v-if="data.items.length > 0" v-bind="settings">
      <div 
        class="program-carousel__item"
        v-for="(item, i) in data.items"
        :key="i"
        data-aos="fade-down"
      >
        <div class="row-container">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="12" sm="12" md="6">
              <div class="program--img">
                <v-img
                  :src="img + 'program/' + item.image"
                  contain
                  max-width="500"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <p class="font-weight-medium text-h5 white--text my-3">
                {{ item.title }}
              </p>
              <p
                class="body1 black--text text-justify white--text accent-2 mb-3 pb-3 program__text"
                v-text="item.text"
              ></p>
            </v-col>
          </v-row>
        </div>
      </div>
    </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  name: 'Programs',
  components: {
    VueSlickCarousel
  },
  data: () => ({
    data: {
      load: true,
      items: [],
    },
    settings: {
      lazyLoad: 'progressive',
      accessibility: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 12000,
      cssEase: 'linear',
      pauseOnDotsHover: true,
      pauseOnFocus: true,
      pauseOnHover: true,
    }

  }),
  mounted() {
    this.getPrograms();
  },
  methods: {
    ...mapActions({
      openSnackbar: 'Snackar/openSnackbar',
    }),

    getPrograms: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'program/all')
        .then(({ data }) => {
          this.data.items = data;
        })
        .catch((err) => {
          console.error('Error al obtener Programs ' + err);
        })
        .then(() => {
          this.data.load = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
    }),
  },
}
</script>
<style scoped>
.programs-carousel{
  height: auto;
  width: 100%;
  padding: 50px;
  background-color: #930b27;
}

.program-carousel__item {
  width: 90%;
}

.program--img .v-image.v-responsive.theme--light {
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.3);
  transition: all ease-in-out 0.3s;
}

.program--img .v-image.v-responsive.theme--light:hover {
  box-shadow: 5px 15px 18px 0px rgba(0, 0, 0, 0.4);
}

.programs__title {
  margin: 0 auto;
  position: relative;
}

.program__text{
  min-height: 150px;
}

.programs__title:after {
  background: #fff;
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 40px;
  transform: translateX(-50%);
}

.slick-prev:before,
.slick-next:before {
  color: #dbd9d9 !important;
  font-size: 30px !important;
}
.slick-prev {
  left: -35px !important;
}
.slick-next {
  right: -35px !important ;
}
.slick-slide {
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (max-width: 600px){
  .row-container .row{
    min-height: 475px;
  }
}

@media screen and (max-width: 900px){
  .row-container .row{
    min-height: 500px;
  }
}

</style>