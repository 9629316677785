<template>
  <div class="my-5 py-5">
    <v-row style="margin: 0">
      <v-col cols="12" sm="12" md="6">
        <div
          class="d-flex flex-column justify-center fill-height objective--text"
          data-aos="fade-right"
        >
          <p class="font-weight-black text-uppercase text-h5">
            {{ data.title }}
          </p>
          <div class="divider"></div>
          <v-img
            v-if="data.image"
            class="d-flex d-sm-flex d-md-none d-lg-none my-5 img--rounded"
            :lazy-src="img + 'objetive/' + data.image"
            :src="img + 'objetive/' + data.image"
            height="300"
          ></v-img>
          <p class="body-1 text-justify black--text">
            {{ data.text }}
          </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 d-none d-md-flex d-lg-flex d-xl-flex"
        data-aos="fade-right"
      >
        <div class="objective--image">
          <v-img
            v-if="data.image"
            :lazy-src="img + 'objetive/' + data.image"
            :src="img + 'objetive/' + data.image"
            max-height="600"
            max-width="600"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Objective',
  data: () => ({
    data: {
      data: {},
    },
  }),
  mounted() {
    this.getObjetive();
  },
  methods: {
    getObjetive: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'objetive/all')
        .then(({ data }) => {
          this.data = data;
        })
        .catch((err) => {
          console.error('Error al obtener Objetivo ' + err);
        })
        .then(() => {
          this.data.load = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
    }),
  },
};
</script>

<style>
.objective--text {
  width: 100%;
  padding: 3.8rem;
}

.objective--image {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 550px;
  max-height: 600px;
  width: 100%;
}

.objective--image .v-image.v-responsive.theme--light,
.img--rounded {
  border-radius: 15px;
  box-shadow: 1px 10px 16px 0px rgba(0, 0, 0, 0.3);
  transition: all ease-in-out 0.3s;
}

.objective--image .v-image.v-responsive.theme--light:hover,
.img--rounded {
  box-shadow: 5px 15px 18px 0px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 960px) {
  .objective--text {
    padding: 2rem;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .objective--text {
    padding: 1.5rem;
  }
}
</style>
