<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <!-- Tabla de Grupos -->
            <v-data-table
              :headers="data.headersGroup"
              :items="data.dataGroups"
              :items-per-page="5"
              :search="search"
              :loading="data.loadGroups"
              loading-text="Cargando datos..."
              sort-by="group"
              class="elevation-0 border-solid"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Listado de Mis Grupos
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="donationGroup(item)"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Ver Grupo</span>
                </v-tooltip>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getGroups">
                  Reiniciar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <GroupDonation
        v-if="selectGroup == true"
        v-bind:group="groupData"
      ></GroupDonation>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GroupDonation from './GroupDonation.vue';
export default {
  components: { GroupDonation },
  name: 'Coordinator',
  data: () => ({
    dialogDelete: false,
    dialog: false,
    donations: [],
    selectGroup: false,
    data: {
      loadGroup: false,
      headersGroup: [
        { text: 'Grupo', value: 'group' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      dataGroups: [],
    },
    groupData: {},
    donationD: {},
    search: '',
  }),
  mounted() {
    this.getGroups();
  },
  methods: {
    ...mapActions({
      getDonor: 'Donor/getDonor',
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    getGroups: async function() {
      this.data.loadGroup = true;
      await this.$http
        .get(this.api + 'donor_group/all/groups_donor')
        .then(({ data }) => {
          this.data.dataGroups = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener datos',
          });
        })
        .then(() => {
          this.data.loadGroup = false;
        });
    },

    donationGroup(item) {
      this.selectGroup = false;
      this.groupData = {};
      this.selectGroup = true;
      this.groupData = item;
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
  },
};
</script>

<style></style>
