<template>
  <v-app v-if="auth">
    <!-- AppBar -->
    <v-app-bar app color="red accent-4" flat clipped-left fixed>
      <v-app-bar-nav-icon
        dark
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/donor/home">
          <v-img src="@/assets/logo-ricaldone.png" width="200"></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y :close-on-content-click="false" class="pr-10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="text-lowercase white--text"
            v-bind="attrs"
            v-on="on"
            light
          >
            {{ user.email }}
            <v-icon right>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Tema Oscuro</v-list-item-title>
            <v-list-item-action>
              <v-switch v-model="$vuetify.theme.dark" color="blue"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="dialogConfirm = true">
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!--  Menu -->
    <v-navigation-drawer v-model="sidebarMenu" app clipped color="amarillo">
      <v-list>
        <v-list-item>
          <v-avatar size="96" class="avatar-content">
            <v-img
              v-if="user.file_photo"
              class="text-center"
              :src="img + 'donor/avatar/' + user.file_photo"
            ></v-img>
          </v-avatar>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-center">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-center"
              v-if="user.id_donor_type == 1"
            >
              Coordinador
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="text-center"
              v-if="user.id_donor_type == 2"
            >
              Donador Frecuente
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="red accent-3">
          <v-list-item key="home" link to="/donor/home">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="my-donations"
            link
            to="/donor/my-donations"
            v-if="user.id_donor_type == 2"
          >
            <v-list-item-icon>
              <v-icon>mdi-playlist-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mis Donaciones</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="my-groups"
            link
            to="/donor/my-groups"
            v-if="user.id_donor_type == 1"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mis Grupos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="doble"
            link
            to="/donor/transactions"
            v-if="user.id_donor_type == 1"
          >
            <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Historial de Donaciones</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="frecuent"
            link
            to="/donor/frecuent-donors"
            v-if="user.id_donor_type == 1"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Historial de Donantes frecuentes</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="donation"
            link
            to="/donor/donation"
            v-if="user.id_donor_type == 2 && user.goal_group != null"
          >
            <v-list-item-icon>
              <v-icon>mdi-hand-heart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Realizar Donación</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item key="profile" link to="/donor/profile">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click.stop="dialogConfirm = true">Cerrar Sesión</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="">
      <transition name="fade">
        <router-view></router-view>
      </transition>

      <Snackbard />

      <v-dialog v-model="dialogConfirm" width="500">
        <v-card>
          <v-card-title class="headline">
            ¿Desea Cerrar Sesion?
          </v-card-title>
          <v-card-text> </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              outlined
              @click.stop="dialogConfirm = false"
              class="mr-2"
            >
              Cancelar
            </v-btn>
            <v-btn
              depressed
              class="white--text"
              color="red accent-4"
              @click="logOut"
            >
              Cerrar sesión
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>

    <v-footer>
      <Copyright />
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Copyright from '@/components/Copyright.vue';
import Snackbard from '@/components/Snackbar';

export default {
  name: 'DashboardDonor',
  components: {
    Copyright,
    Snackbard,
  },
  data: () => ({
    auth: false,
    sidebarMenu: true,
    dialogConfirm: false,
    selectedItem: 0,
    items: [
      { text: 'Inicio', href: '/donor/home', icon: 'mdi-home' },
      {
        text: 'Mis Donaciones',
        href: '/donor/my-donations',
        icon: 'mdi-playlist-check',
      },
      {
        text: 'Mis Grupos',
        href: '/donor/my-groups',
        icon: 'mdi-account-group',
      },
      {
        text: 'Realiza Donación',
        href: '/donor/donation',
        icon: 'mdi-hand-heart',
      },
      { text: 'Mi Perfil', href: '/donor/profile', icon: 'mdi-account' },
    ],
  }),
  mounted() {
    this.welcome();
  },
  methods: {
    ...mapActions({
      getDonor: 'Donor/getDonor',
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    welcome: function() {
      let token = undefined;
      if (this.$route.params.token) {
        token = this.$route.params.token;
        this.$store.commit('setToken', token);
        sessionStorage.setItem('donor_frequent_token', token);
        this.$route.push('/donor');
      } else {
        if (!this.$store.token) {
          token = sessionStorage.getItem('donor_frequent_token');
          if (token) {
            this.$store.commit('setToken', token);
          }
        }
      }
      if (token) {
        this.auth = true;
        this.$http.defaults.headers['Authorization'] = token;
        this.getDonor(this);
      } else {
        this.$router.push('/');
      }
    },

    logOut: function() {
      this.$http.get(this.api + 'logout').then(({ data }) => {
        this.$http.defaults.headers['Authorization'] = null;
        sessionStorage.removeItem('donor_frequent_token');
        this.$router.push('/');
      });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
      token: 'Donor/getToken',
      user: 'Donor/getDonor',
    }),
  },
};
</script>

<style></style>
