<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="border-solid">
          <div class="mb-10">
            <v-row>
              <v-col cols="12" sm="12" md="4" class="align-self-stretch">
                <div>
                  <v-card class="mb-5">
                    <v-card-text>
                      <div
                        class="d-flex flex-column align-center justify-center"
                      >
                        <v-avatar class="mb-5" size="96">
                          <v-img
                            v-if="user.file_photo"
                            :src="img + 'donor/avatar/' + user.file_photo"
                          ></v-img>
                        </v-avatar>
                        <p class="title mb-0">{{ user.name }}</p>
                        <p
                          class="subtitle-1 mb-0"
                          v-if="user.id_donor_type == 1"
                        >
                          Coordinador
                        </p>
                        <p
                          class="subtitle-1 mb-0"
                          v-if="user.id_donor_type == 2"
                        >
                          Donador Frecuente
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>

                  <v-form>
                    <v-card>
                      <v-card-title>Modificar Contraseña</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              label="Contraseña Actual"
                              outlined
                              hide-details
                              v-model="pass.old_password"
                              :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showOld ? 'text' : 'password'"
                              @click:append="showOld = !showOld"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              label="Contraseña Nueva"
                              outlined
                              hide-details
                              v-model="pass.new_password"
                              :append-icon="
                                showNew1 ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="showNew1 ? 'text' : 'password'"
                              @click:append="showNew1 = !showNew1"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              label="Repetir Contraseña Nueva"
                              outlined
                              hint=""
                              v-model="pass.new_password_second"
                              :append-icon="
                                showNew2 ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="showNew2 ? 'text' : 'password'"
                              :rules="[
                                () =>
                                  pass.new_password_second ===
                                    pass.new_password ||
                                  'Las contraseñas no coinciden',
                              ]"
                              @click:append="showNew2 = !showNew2"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="amarillo"
                          text
                          outlined
                          @click="updatePass"
                          :loading="loadingPass"
                        >
                          <v-icon left>
                            mdi-content-save
                          </v-icon>
                          Modificar Contraseña
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </div>
              </v-col>
              <v-col>
                <v-form>
                  <v-card>
                    <v-card-title>Mi Perfil</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row v-if="user.id_donor_type == 2">
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Documento de Identidad"
                            type="text"
                            outlined
                            hide-details
                            v-model="user.dui"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" v-if="user.nit != null">
                          <v-text-field
                            label="NIT"
                            type="text"
                            outlined
                            hide-details
                            v-model="user.nit"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            label="Nombre completo"
                            type="text"
                            outlined
                            hide-details
                            v-model="user.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="user.id_donor_type == 2">
                        <v-col cols="12">
                          <v-textarea
                            label="Dirección de domicilio"
                            type="text"
                            rows="3"
                            outlined
                            hide-details
                            v-model="user.address"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Correo electrónico"
                            type="email"
                            outlined
                            hide-details
                            v-model="user.email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Teléfono"
                            type="text"
                            outlined
                            hide-details
                            v-model="user.phone"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="user.id_donor_type == 2">
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            label="Año de promoción"
                            outlined
                            persistent-hint
                            hint="Si fue ex alumno, por favor indique su promoción"
                            v-model="user.promotion"
                            :items="promotions"
                            item-text="promotion"
                            item-value="promotion"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Promesa de Donación anual"
                            required
                            outlined
                            hide-details
                            readonly
                            v-model="user.goal_group"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-file-input
                            label="Subir Foto"
                            prepend-icon=""
                            prepend-inner-icon="mdi-camera"
                            outlined
                            v-model="user.photo"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-3">
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        color="amarillo"
                        text
                        @click="updateProfile"
                      >
                        <v-icon left>
                          mdi-content-save
                        </v-icon>
                        Guardar Cambios
                      </v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                  </v-card>
                </v-form>
              </v-col>
            </v-row>
            <Snackbar />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Snackbar from '@/components/Snackbar.vue';
export default {
  components: { Snackbar },
  name: 'Profile',
  data: () => ({
    showOld: false,
    showNew1: false,
    showNew2: false,
    loadingPass: false,
    promotions: [],
    months: [],
    pass: {},
  }),

  mounted() {
    this.getPromotions();
    this.getMonths();
  },
  methods: {
    ...mapActions({
      getDonor: 'Donor/getDonor',
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    getPromotions: function() {
      let notProm = 'No soy ex-alumno';
      this.promotions.push(notProm);
      let year = new Date().getFullYear();
      year--;
      for (year; year > 1960; year--) {
        this.promotions.push(year);
      }
    },

    getMonths: function() {
      let oneMonth = '1 Mes';
      let month;
      this.months.push(oneMonth);
      for (let index = 2; index <= 12; index++) {
        month += this.months.push(index + ' Meses');
      }
    },

    updateProfile: async function() {
      await this.$http
        .post(this.api + 'profile/update_info', this.toFormData(this.user))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
        })
        .catch(err => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        });
    },

    updatePass: async function() {
      await this.$http
        .post(this.api + 'profile/update_password', this.pass)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          this.pass = {};
        })
        .catch(err => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
      token: 'Donor/getToken',
      user: 'Donor/getDonor',
      authentication: 'Donor/getAuthentication',
    }),
  },
  watch: {
    user() {},
  },
};
</script>

<style></style>
