<template>
  <div data-aos="zoom-in">
    <v-stepper v-if="!finish" v-model="e1" class="elevation-1">
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="e1 > 1" step="1" color="secondary">
          Datos Personales
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" color="secondary">
          Método de Pago
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="3" color="secondary">
          Confirmación
        </v-stepper-step>
      </v-stepper-header>

      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form>
          <!-- Form -->
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="elevation-0">
                <v-row>
                  <v-col>
                    <v-select
                      label="Tipo de Persona"
                      required
                      outlined
                      hint="Si es una empresa, seleccione Persona Jurídica"
                      persistent-hint
                      :items="person_types"
                      item-text="person_type"
                      item-value="person_type"
                      v-model="pagoForm.person_type"
                      @change="personType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="legal">
                  <v-col>
                    <v-text-field
                      label="Nombre de la Institución"
                      outlined
                      hide-details
                      v-model="pagoForm.institution_name"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-file-input
                      label="Subir NIT de la institución"
                      outlined
                      hint="Copia del NIT en formato PDF"
                      persistent-hint
                      prepend-icon
                      prepend-inner-icon="mdi-pdf-box"
                      show-size
                      accept="application/pdf"
                      v-model="pagoForm.photo_nit"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-if="!legal">
                  <v-col cols="12">
                    <div class="d-flex align-center justify-center mx-auto">
                      <v-checkbox
                        label="¿Desea comprobante?"
                        hide-details
                        class="d-inline-block mt-0 mr-3"
                        v-model="pagoForm.voucher"
                        @change="requiredVoucher"
                      ></v-checkbox>
                      <v-tooltip v-if="!pagoForm.voucher" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span
                          >El comprobante es solo para motivos de declaración de
                          renta y solo es para residentes de El Salvador</span
                        >
                      </v-tooltip>
                    </div>
                    <div>
                      <p class="text-caption text-center pb-0">
                        El comprobante es solo para motivos de declaración de
                        renta y solo es para residentes de El Salvador
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      label="Seleccione su país"
                      outlined
                      hide-details
                      v-model="pagoForm.id_country"
                      :items="countries"
                      item-text="country"
                      item-value="id_country"
                      v-if="!pagoForm.voucher"
                      @change="selectedCountry"
                    ></v-autocomplete>
                    <div v-if="pagoForm.voucher">
                      <v-text-field
                        label="NIT"
                        type="text"
                        outlined
                        hide-details
                        v-model="pagoForm.nit"
                        v-mask="'####-######-###-#'"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <div v-if="pagoForm.id_country != 68">
                      <v-text-field
                        label="Documento Único de Identidad"
                        type="text"
                        outlined
                        hide-details
                        v-model="pagoForm.dui_sin_mask"
                      ></v-text-field>
                    </div>
                    <div v-if="pagoForm.id_country == 68">
                      <validation-provider
                        v-slot="{ errors }"
                        name="DUI"
                        :rules="{
                          required: true,
                          regex: '^\\d{8}-\\d{1}$',
                        }"
                      >
                        <v-text-field
                          label="DUI"
                          type="text"
                          outlined
                          :error-messages="errors"
                          v-model="pagoForm.dui_mask"
                        ></v-text-field>
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Nombre completo según documento"
                      required
                      type="text"
                      outlined
                      hide-details
                      v-model="pagoForm.name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Dirección de domicilio"
                      required
                      type="text"
                      rows="3"
                      outlined
                      hide-details
                      v-model="pagoForm.address"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Correo"
                      rules="required|email"
                    >
                      <v-text-field
                        label="Correo electrónico"
                        required
                        outlined
                        v-model="pagoForm.email"
                        :error-messages="errors"
                        type="email"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <div v-if="pagoForm.id_country == 68">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Teléfono"
                        :rules="{
                          required: true,
                          regexPhone: '^(2|6|7){1}\\d{3}-\\d{4}$',
                        }"
                      >
                        <v-text-field
                          label="Teléfono"
                          :error-messages="errors"
                          required
                          type="text"
                          outlined
                          v-model="pagoForm.phone"
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div v-if="pagoForm.id_country != 68">
                      <v-text-field
                        label="Teléfono"
                        required
                        type="text"
                        outlined
                        hide-details
                        v-model="pagoForm.phone"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      label="Donar a:"
                      outlined
                      hide-details
                      v-model="pagoForm.id_donation_type"
                      :items="typeDonations"
                      item-text="donation_type"
                      item-value="id_donation_type"
                      @change="slcDonationType"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      label="Año de promoción"
                      required
                      outlined
                      persistent-hint
                      hint="Si fue ex alumno, por favor indique su promoción"
                      v-model="pagoForm.promotion"
                      :items="promotions"
                      item-text="promotion"
                      item-value="promotion"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn color="primary" block @click="step1" :disabled="invalid">
                Siguiente
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="elevation-0">
                <v-row>
                  <v-col>
                    <div class="d-flex justify-center align-center mb-5">
                      <v-img
                        lazy-src="@/assets/visa-master.png"
                        max-width="200"
                        src="@/assets/visa-master.png"
                        class="mx-auto"
                      ></v-img>
                      <v-img
                        lazy-src="@/assets/logo-union-pay.png"
                        max-width="120"
                        src="@/assets/logo-union-pay.png"
                        class="mx-auto"
                      ></v-img>
                      <v-img
                        lazy-src="@/assets/logo-serfinsa.png"
                        max-width="200"
                        src="@/assets/logo-serfinsa.png"
                        class="mx-auto"
                      ></v-img>
                    </div>
                    <p class="mb-0">
                      <b>Nota: </b>La información de su tarjeta de crédito o
                      débito no se almacenan en ningún lugar, ya que la
                      transacción se realiza por medio de
                      <a href="https://www.redserfinsa.com/" target="_blank"
                        >Serfinsa</a
                      >
                      y además de aceptar cualquier tipo de tarjeta ya sean
                      <i>visa, mastercard o union pay</i>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Nombre de la Tarjeta"
                      type="text"
                      required
                      outlined
                      hide-details
                      v-model="pagoForm.card_name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Número de Tarjeta"
                      required
                      outlined
                      hide-details
                      v-mask="'#### #### #### ####'"
                      v-model="pagoForm.card_number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Fecha de expiración (mm/yy)"
                      required
                      outlined
                      hide-details
                      v-mask="'##/##'"
                      v-model="pagoForm.expiration_date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Código de seguridad CVV"
                      required
                      outlined
                      hide-details
                      v-mask="'###'"
                      v-model="pagoForm.cvv"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Cantidad a donar"
                      required
                      outlined
                      prepend-inner-icon="mdi-currency-usd"
                      v-model="pagoForm.amount"
                      hint="La cantidad minima a donar son $ 5.00"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-btn text @click="e1 = 1" block> Regresar </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-btn color="primary" @click="step2" block>
                      Siguiente
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>

            <!-- STEP 3 -->
            <v-stepper-content step="3">
              <v-card class="elevation-0">
                <p class="body-1">
                  <span class="font-weight-medium">Nombre completo:</span>
                  {{ pagoForm.name }}
                </p>
                <p class="body-1">
                  <span class="font-weight-medium">Número de Tarjeta:</span>
                  {{ pagoForm.card_number | hideCard }}
                </p>
                <p class="body-1">
                  <span class="font-weight-medium">Cantidad a donar:</span>
                  $ {{ pagoForm.amount }}
                </p>
                <p class="body-1">
                  <span class="font-weight-medium">Donación para:</span>
                  {{ selectedDonationType.donation_type }}
                </p>
                <v-checkbox
                  label="¿Desea ser donante frecuente?"
                  hide-details
                  class="d-inline-block mx-auto"
                  v-model="pagoForm.frequent_donor"
                  @change="changeFrequentDonor"
                ></v-checkbox>
                <p class="text-caption">
                  <span class="font-weight-medium">Nota: </span>
                  Si desea ser donante frecuente podrá iniciar sesión en esta
                  plataforma, ser parte de un grupo de donadores frecuentes si
                  lo desea y realizar donaciones rapidamente.
                </p>
                <div v-if="pagoForm.frequent_donor">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        label="Seleccionar grupo de donación"
                        required
                        outlined
                        persistent-hint
                        hint="Seleccione un grupo con el que se identifique"
                        v-model="pagoForm.id_group"
                        :items="groups"
                        item-text="group"
                        item-value="id_group"
                        @change="slcGroup"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="pagoForm.id_group">
                    <v-col class="pb-0">
                      <p class="text-justify">
                        <b>Grupo:</b> {{ selectedGroup.group }}
                      </p>
                      <p class="text-justify">
                        <b>Descripción:</b> {{ selectedGroup.description }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col class="d-flex flex-column justify-center align-center">
                    <p class="text-body-1">
                      ¡Queremos comprobar que eres un humano! Completa el
                      captcha
                    </p>
                    <!-- Turnstile -->
                    <turnstile ref="turnstile" @verified="handleVerification" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex flex-column justify-center align-center">
                    <h6 class="text-h6">Comprobación de identidad</h6>
                    <p class="text-body-1 mx-3">
                      Hemos envíado un código de 6 digitos a tu correo
                      electrónico, verifica tu bandeja de entrada o bandeja de
                      Spam e ingresa el correo que te hemos enviado.
                    </p>
                    <v-text-field
                      label="Código de verificación"
                      required
                      outlined
                      hide-details
                      v-mask="'######'"
                      v-model="pagoForm.codigo_verificacion"
                    ></v-text-field>
                    <p
                      v-if="verificationCodeTimerLabel > 0"
                      class="text-body-2 my-1"
                    >
                      Reenviar en {{ verificationCodeTimerLabel }} segundos
                    </p>
                    <v-btn
                      class="mt-3"
                      color="secondary"
                      :disabled="verificationCodeTimerLabel > 0"
                      @click="reenviarCodigo"
                    >
                      Solicitar código de verificación
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-5">
                  <v-col cols="12" sm="12" md="6">
                    <v-btn text @click="e1 = 2" block> Regresar </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-btn
                      color="primary"
                      @click="step3"
                      :loading="loading"
                      block
                      :disabled="!isValidCodeVerification"
                    >
                      Finalizar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-form>
      </validation-observer>
    </v-stepper>
    <div v-else class="d-flex justify-center">
      <v-card max-width="500px">
        <v-card-text class="text--primary">
          <h4 class="text-center text-h4">¡Gracias por tu donación!</h4>
          <p class="text-center text-subtitle-1">
            Has apoyado a: {{ selectedDonationType.donation_type }}, tu
            contribución es muy importante para continuar con este tipo
            programas sociales.
          </p>
          <v-divider></v-divider>
          <p
            v-if="pagoForm.frequent_donor"
            class="text-center text-subtitle-1 mb-0"
          >
            Ha sido enviado un correo eléctronico a la dirección
            {{ pagoForm.email }}, con la contraseña para iniciar sesión en este
            sitio web.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" to="/">Volver al inicio</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { isAxiosError } from "axios";

import Turnstile from "@/components/Turnstile.vue";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} debe tener {length} dígitos. ({_value_})",
});

extend("required", {
  ...required,
  message: "El {_field_} no puede ser vacío",
});

extend("email", {
  ...email,
  message: "El Correo electrónico debe ser valido",
});

extend("regex", {
  ...regex,
  message: "El {_field_} debe ser ej. 01234567-9",
});

extend("regexPhone", {
  ...regex,
  message: "El {_field_} debe iniciar en 2, 6 o 7 y debe ser ej. 2222-2222",
});

export default {
  name: "FormDonation",
  components: {
    ValidationProvider,
    ValidationObserver,
    Turnstile,
  },
  data: () => ({
    e1: 1,
    pagoForm: {
      amount_serfinsa: 0,
      codigo_verificacion: "",
    },
    person_types: ["Natural", "Juridica"],
    legal: false,
    countries: [],
    typeDonations: [],
    promotions: [],
    groups: [],
    months: [],
    data: {
      load: true,
    },
    masks: {
      dui: "",
      nit: "'####-######-###-#'",
      phone: "",
    },
    selectedDonationType: {},
    selectedGroup: {},
    selectCountry: false,
    finish: false,
    loading: false,
    token: null,
    verificationCodeTimerLabel: 0,
    verificationCodeCountdownInterval: null,
  }),
  mounted() {
    this.getCurrentActions("Donación");
    this.getCountries();
    this.getTypeDonations();
    this.getPromotions();
    this.getGroups();
    this.getMonths();
  },
  methods: {
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    startTimer() {
      this.verificationCodeTimerLabel = 60;
      this.verificationCodeCountdownInterval = setInterval(() => {
        if (this.verificationCodeTimerLabel > 0) {
          this.verificationCodeTimerLabel--;
        } else {
          clearInterval(this.verificationCodeCountdownInterval);
        }
      }, 1000);
    },
    reenviarCodigo() {
      if (
        this.pagoForm.email === undefined ||
        this.pagoForm.email === null ||
        this.pagoForm.email == ""
      ) {
        this.openSnackbar({
          type: "danger",
          message:
            "Debes especificar un correo electrónico para recibir un código de verificación",
        });
        return;
      }
      this.startTimer();
      this.$http
        .post(this.api + "tx/donation/verification-code", {
          email: this.pagoForm.email,
        })
        .then(({ data }) => {
          this.openSnackbar({
            type: "success",
            message: data.message,
          });
        })
        .catch((err) => {
          if (isAxiosError(err)) {
            this.openSnackbar({
              type: "danger",
              message: err.response.data.message,
            });
            return;
          }

          this.openSnackbar({
            type: "danger",
            message:
              "Ha ocurrido un error desconocido al intentar solicitar el código de verificación",
          });
        });
    },

    handleVerification(token) {
      this.token = token;
    },

    getCountries: async function () {
      await this.$http
        .get(this.api + "country")
        .then(({ data }) => {
          this.countries = data;
        })
        .catch((err) => {
          console.error("Error al obtener Paises: " + err);
        });
    },

    getTypeDonations: async function () {
      await this.$http
        .get(this.api + "donation_type/public")
        .then(({ data }) => {
          this.typeDonations = data;
        })
        .catch((err) => {
          console.error("Error al obtener Tipos de Donaciones: " + err);
        });
    },

    getPromotions: function () {
      let notProm = "No soy ex-alumno";
      this.promotions.push(notProm);
      let year = new Date().getFullYear();
      year--;
      for (year; year > 1960; year--) {
        this.promotions.push(year);
      }
    },

    getGroups: async function () {
      await this.$http
        .get(this.api + "group")
        .then(({ data }) => {
          this.groups = data;
        })
        .catch((err) => {
          console.error("Error al obtener Grupos de Donadores: " + err);
        });
    },

    getMonths: function () {
      let oneMonth = "1 Mes";
      let month;
      this.months.push(oneMonth);
      for (let index = 2; index <= 12; index++) {
        month += this.months.push(index + " Meses");
      }
    },

    step1: function () {
      let validate =
        this.pagoForm.person_type &&
        (this.pagoForm.dui_mask || this.pagoForm.dui_sin_mask) &&
        this.pagoForm.name &&
        this.pagoForm.address &&
        this.pagoForm.email &&
        this.pagoForm.phone &&
        this.pagoForm.id_donation_type;

      if (this.pagoForm.voucher) {
        validate = validate && this.pagoForm.nit;
        this.pagoForm.id_country = 68;
      } else {
        validate = validate && this.pagoForm.id_country;
        this.pagoForm.nit = null;
      }

      if (validate) {
        if (this.pagoForm.dui_sin_mask != null) {
          this.pagoForm.dui = this.pagoForm.dui_sin_mask;
        } else {
          this.pagoForm.dui = this.pagoForm.dui_mask;
        }
        this.e1++;
      } else {
        this.openSnackbar({
          type: "danger",
          message: "Error, no deje campos vacios",
        });
      }
    },

    step2: function () {
      let validate =
        this.pagoForm.card_name &&
        this.pagoForm.card_number &&
        this.pagoForm.expiration_date &&
        this.pagoForm.cvv &&
        this.pagoForm.amount;

      if (validate) {
        if (!isNaN(this.pagoForm.amount) && this.pagoForm.amount >= 5) {
          this.pagoForm.amount = parseFloat(this.pagoForm.amount).toFixed(2);
          this.pagoForm.amount_serfinsa = this.pagoForm.amount;
          this.e1++;
        } else {
          this.openSnackbar({
            type: "danger",
            message: "Favor verificar la cantidad a donar",
          });
        }
      } else {
        this.openSnackbar({
          type: "danger",
          message: "Favor llenar todos los campos",
        });
      }
    },

    step3: function () {
      if (this.pagoForm.frequent_donor) {
        this.pagoForm.frequent_donor = 1;
        if (this.pagoForm.id_group) {
          if (this.pagoForm.voucher) {
            this.pagoForm.voucher = 1;
            this.donation();
          } else {
            this.pagoForm.voucher = 0;
            this.donation();
          }
        } else {
          this.openSnackbar({
            type: "warning",
            message: "Por favor, seleccione un grupo al que desea pertenecer",
          });
        }
      } else {
        this.pagoForm.frequent_donor = 0;
        this.donation();
      }
    },

    // Peticion a API para realizar donación
    donation: function (data) {
      this.loading = true;
      //format monto
      let monto = "000000000000";
      let str_monto = this.pagoForm.amount.replace(".", "");
      this.pagoForm.amount_serfinsa =
        monto.substr(0, monto.length - str_monto.length) + str_monto;

      let str_tarjeta = this.pagoForm.card_number;
      this.pagoForm.card_number_serfinsa = str_tarjeta.replace(/\s/g, "");

      let str_exp = this.pagoForm.expiration_date;
      var exp = str_exp.split("/");
      this.pagoForm.expiration_date_serfinsa = exp[1] + exp[0];

      this.pagoForm.cvv_serfinsa = "1611 " + this.pagoForm.cvv;
      this.pagoForm.voucher = this.pagoForm.voucher ? 1 : 0;
      this.pagoForm.cf_token = this.token;

      this.$http
        .post(this.api + "/tx/donation_public", this.toFormData(this.pagoForm))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.finish = true;
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error_message) {
            this.openSnackbar({
              type: "danger",
              message: err?.response?.data?.error_message,
            });
            return;
          }

          if (isAxiosError(err)) {
            this.openSnackbar({
              type: "danger",
              message: err.response.data.message,
            });
            return;
          }

          this.openSnackbar({
            type: "danger",
            message:
              "Ha ocurrido un error desconocido al intentar procesar la donación",
          });
        })
        .then(() => {
          this.loading = false;
        })
        .finally(() => {
          this.$refs.turnstile.reload();
        });
    },

    requiredVoucher: function () {
      this.pagoForm.nit = null;
      this.pagoForm.dui = null;
      this.pagoForm.document = null;
      this.pagoForm.phone = null;
      if (this.pagoForm.voucher) {
        this.pagoForm.id_country = 68;
      } else {
        this.pagoForm.id_country = null;
      }
    },

    changeFrequentDonor: function () {},

    personType: function () {
      if (this.pagoForm.person_type == "Juridica") {
        this.legal = true;
      } else {
        this.legal = false;
      }
    },

    selectedCountry: function () {
      if (this.pagoForm.id_country == 68) {
        this.pagoForm.dui_sin_mask = null;
        this.selectCountry = true;
      } else {
        this.pagoForm.dui_mask = null;
        this.selectCountry = false;
      }
    },

    slcDonationType: function () {
      //filtra todos los que sean diferentes a la id seleccionada (filtrar = convervar)
      let find = this.typeDonations.filter(
        (type) => type.id_donation_type == this.pagoForm.id_donation_type
      );
      //hay algo? entonces la posicion 0 sera el seleccionado
      this.selectedDonationType = find.length > 0 ? find[0] : null;
    },

    slcGroup: function () {
      //filtra todos los que sean diferentes a la id seleccionada (filtrar = convervar)
      let find = this.groups.filter(
        (group) => group.id_group == this.pagoForm.id_group
      );
      //hay algo? entonces la posicion 0 sera el seleccionado
      this.selectedGroup = find.length > 0 ? find[0] : null;
    },
  },
  filters: {
    hideCard: function (card) {
      return card
        ? card.substring(0, 4) +
            " **** **** " +
            card.substring(card.length - 4, card.length)
        : "";
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      actions: "getCurrentActions",
    }),
    isValidCodeVerification() {
      return this.pagoForm?.codigo_verificacion?.length === 6;
    },
  },
  destroyed() {
    if (this.verificationCodeCountdownInterval) {
      clearInterval(this.verificationCodeCountdownInterval);
    }
  },
  watch: {
    e1(currentStep, prevStep) {
      if (currentStep == 3) {
        // this.reenviarCodigo(); // Note: Se ha dejado que se solicite el código manualmente
      }
    },
  },
};
</script>

<style>
.theme--light.v-stepper {
  box-shadow: -4px -4px 3px 0px rgb(0 0 0 / 30%) inset !important;
}
</style>
