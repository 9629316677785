<template>
  <v-app>
    <div class="carousel" data-aos="fade-in">
      <v-carousel hide-delimiter-background show-arrows-on-hover height="100%">
        <v-carousel-item
          v-for="(item, i) in data.items"
          eager
          :key="i"
          :src="img + 'slider/' + item.image"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-row
            v-if="item.title || item.text || item.link"
            class="d-flex flex-column align-start align-sm-center justify-end fill-height fill-width mx-10 carousel--content"
            data-aos="zoom-in"
          >
            <div class="pb-10 fill-width overlay-container">
              <div class="overlay-text-carousel px-5 py-3 mb-10">
                <div
                  v-if="item.title"
                  class="display-1 mb-3 carousel--title"
                  v-text="item.title"
                ></div>
                <div v-if="item.title" class="divider"></div>
                <div class="text-left">
                  <p
                    v-if="item.text"
                    v-text="item.text"
                    class="carousel--text mb-3"
                  ></p>
                  <v-btn
                    v-if="item.link"
                    outlined
                    color="white"
                    :href="item.link"
                    target="_blank"
                  >
                    Ver más
                  </v-btn>
                </div>
              </div>
            </div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Carousel',
  data: () => ({
    data: {
      load: true,
      items: [],
    },
  }),
  mounted() {
    this.getSliders();
  },
  methods: {
    ...mapActions({
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    getSliders: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'slider/all')
        .then(({ data }) => {
          this.data.items = data;
        })
        .catch((err) => {
          console.error('Error al obtener Sliders ' + err);
        })
        .then(() => {
          this.data.load = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
    }),
  },
};

// Mobile Height https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
// Primero obtenemos la altura de la ventana gráfica y la multiplicamos por 1% para obtener un valor para una unidad vh
let vh = window.innerHeight * 0.01;
// Luego establecemos el valor en la propiedad personalizada --vh en la raíz del documento
document.documentElement.style.setProperty('--vh', `${vh}px`);
</script>

<style scoped>
.overlay-text-carousel {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.overlay-text-carousel .divider {
  height: 3px !important;
  margin: 0.3rem 0;
}

.carousel {
  height: 100vh;
  widows: 100%;
}

.carousel--content {
  padding-left: 64px;
  width: 50%;
}

.overlay-container{
  width: 100%;
}

.carousel--text {
  font-size: 1.5rem;
}

/* Responsive */
@media (max-width: 600px) {
  .carousel {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
  }

  .carousel .carousel--content {
    padding: 0rem 1rem !important;
    margin: 0 !important;
    width: 100%;
  }

  .carousel .carousel--content .carousel--title {
    font-size: 2.2rem !important;
    font-weight: bold;
  }

  .carousel--text {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 768px) {
  .carousel .carousel--content {
    padding: 0;
    width: 100%;
  }
}
</style>
