<template>
  <v-app v-if="auth">
    <!-- AppBar -->
    <v-app-bar app color="rojo" flat fixed clipped-left>
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="pl-0">
        <router-link to="/private/home">
          <v-img src="@/assets/logo-ricaldone.png" width="200"></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y :close-on-content-click="false" class="pr-10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="text-lowercase white--text"
            v-bind="attrs"
            v-on="on"
            light
          >
            {{ user.email }}
            <v-icon right>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Tema Oscuro</v-list-item-title>
            <v-list-item-action>
              <v-switch v-model="$vuetify.theme.dark" color="blue"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="dialogConfirm = true">
            <v-list-item-title>Volver a Core</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Menu -->
    <v-navigation-drawer v-model="sidebarMenu" app clipped color="amarillo">
      <v-list>
        <v-list-item>
          <v-avatar size="96" class="avatar-content">
            <v-img
              class="text-center"
              :src="coreImg + 'user/' + user.photo"
            ></v-img>
          </v-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-center">
              {{ user.first_name }}
              <br />
              {{ user.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-center">
              {{ userType.user_type }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(item, index) in itemsAccess"
            :key="index"
            link
            :to="item.href"
            color="rojo"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group
          v-if="accountingsAccess.items.length > 0"
          v-model="accountingsAccess.active"
          :prepend-icon="accountingsAccess.action"
          no-action
          color="rojo"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="accountingsAccess.title"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in accountingsAccess.items"
            :key="child.title"
            link
            :to="child.href"
            color="rojo"
          >
            <v-list-item-title v-text="child.title"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-subheader>Catálogos</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(catalog, i) in catalogsAccess"
            :key="i"
            link
            :to="catalog.href"
            color="rojo"
          >
            <v-list-item-icon>
              <v-icon v-text="catalog.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="catalog.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group
          v-for="item in sectionsAccess"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            link
            :to="child.href"
          >
            <v-list-item-title v-text="child.title"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <transition name="fade">
        <router-view></router-view>
      </transition>

      <Snackbard />

      <v-dialog v-model="dialogConfirm" max-width="270px">
        <v-card>
          <v-card-title class="tex-center">
            <v-icon color="primary" left>mdi-progress-question</v-icon>
            Información
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mb-0 mt-5 text-primary">¿Desea volver a Core?</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancelar
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="logOut"
              :loading="isLoading"
              >Volver a Core</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>

    <v-footer>
      <Copyright />
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Snackbard from '@/components/Snackbar';
import Copyright from '@/components/Copyright.vue';

export default {
  name: 'DashboardView',
  components: {
    Snackbard,
    Copyright,
  },
  data: () => ({
    sidebarMenu: true,
    dialogConfirm: false,
    isLoading: false,
    selectedItem: 0,
    items: [
      {
        text: 'Inicio',
        href: '/private/home',
        icon: 'mdi-home',
        access: 'Inicio',
      },
    ],
    accountings: [
      {
        title: 'Contabilidad',
        action: 'mdi-folder',
        items: [
          {
            title: 'Grupos',
            href: '/private/accounting/groups',
            icon: 'mdi-view-carousel',
            access: 'Contabilidad Grupos',
          },
          {
            title: 'Donaciones',
            href: '/private/accounting/donations',
            icon: 'mdi-ticket',
            access: 'Historial de Donaciones',
          },
        ],
      },
    ],
    catalogs: [
      {
        text: 'Donadores Frecuentes',
        href: '/private/donor',
        icon: 'mdi-account-box-multiple',
        access: 'Donadores Frecuentes',
      },
      {
        text: 'Coordinadores',
        href: '/private/coordinator',
        icon: 'mdi-account-multiple',
        access: 'Coordinador',
      },
      {
        text: 'Personas Juridicas',
        href: '/private/legal',
        icon: 'mdi-account-alert-outline',
        access: 'Personas Jurídicas',
      },
      {
        text: 'Programas',
        href: '/private/donation-type',
        icon: 'mdi-gift',
        access: 'Tipo de donación',
      },
      {
        text: 'Países',
        href: '/private/country',
        icon: 'mdi-earth',
        access: 'Paises',
      },
      {
        text: 'Grupos de Donación',
        href: '/private/donation-group',
        icon: 'mdi-account-group',
        access: 'Grupo de donación',
      },
    ],
    sections: [
      {
        title: 'Secciones',
        action: 'mdi-view-quilt',
        items: [
          {
            title: 'Objetivo',
            href: '/private/article/objective',
            icon: 'mdi-view-stream',
            access: 'Objetivo',
          },
          {
            title: 'Cabecera',
            href: '/private/article/header',
            icon: 'mdi-view-carousel',
            access: 'Cabecera',
          },
          {
            title: 'Programas',
            href: '/private/article/program',
            icon: 'mdi-ticket',
            access: 'Programas',
          },
          {
            title: 'Casos',
            href: '/private/article/case',
            icon: 'mdi-ticket',
            access: 'Casos',
          },
        ],
      },
    ],
  }),
  mounted() {
    this.welcome();
  },
  methods: {
    ...mapActions({
      getUser: 'getUser',
      getUserType: 'getUserType',
      getPermissions: 'getPermissions',
      getActionsPermissions: 'getActionsPermissions',
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    welcome: function() {
      let token = undefined;
      if (this.$route.params.token && this.$route.params.token.length > 20) {
        token = this.$route.params.token;
        this.$store.commit('setToken', token);
        sessionStorage.setItem('donation_token', token);
        this.$router.push('/private/home');
      } else {
        //aqui deberia pedir ver si el token que tengo en local sesion storage existe en usuarios.
        if (!this.$store.token) {
          token = sessionStorage.getItem('donation_token');
          if (token) {
            this.$store.commit('setToken', token);
          }
        }
      }
      if (token) {
        //token de autorizacion peticiones
        this.$http.defaults.headers['Authorization'] = token;
        //data del usuario
        this.getUser(this);
        //permisos
        this.getUserType(this);
        this.getPermissions(this);
        this.getActionsPermissions(this);
      } else {
        window.location = 'http://core.ricaldone.edu.sv/';
      }
    },
    closeDelete() {
      this.dialogConfirm = false;
    },
    logOut: function() {
      window.location = this.core + '/' + this.token;
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  computed: {
    ...mapGetters({
      token: 'getToken',
      user: 'getUser',
      auth: 'getAuth',
      userType: 'getUserType',
      core: 'getCoreURL',
      coreImg: 'getCoreImg',
      permissions: 'getPermissions',
    }),
    //
    itemsAccess() {
      let items = [];
      this.items.forEach((item) => {
        this.permissions.forEach((permission) => {
          if (this.searchInArray(permission.permission, item.access)) {
            items.push(item);
          }
        });
      });
      return items;
    },
    accountingsAccess() {
      let items = [];
      this.accountings[0].items.forEach((item) => {
        this.permissions.forEach((permission) => {
          if (this.searchInArray(permission.permission, item.access)) {
            items.push(item);
          }
        });
      });
      let access = {
        title: this.accountings[0].title,
        action: this.accountings[0].action,
        items: items,
      };
      return access;
    },
    catalogsAccess() {
      let items = [];
      this.catalogs.forEach((item) => {
        this.permissions.forEach((permission) => {
          if (this.searchInArray(permission.permission, item.access)) {
            items.push(item);
          }
        });
      });
      return items;
    },
    sectionsAccess() {
      let items = [];
      this.sections[0].items.forEach((item) => {
        this.permissions.forEach((permission) => {
          if (this.searchInArray(permission.permission, item.access)) {
            items.push(item);
          }
        });
      });
      return [
        {
          title: this.sections[0].title,
          action: this.sections[0].action,
          items: items,
        },
      ];
    },
  },
};
</script>

<style>
.avatar-content {
  margin: 0 auto;
}
.v-list-item__title {
  cursor: pointer;
}
.text-black {
  color: #000;
}
</style>
