<template>
  <div>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="donor == true">
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Perfil de Donador Frecuente</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-card>
              <!-- Tabs -->
              <v-tabs
                v-model="tab"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  Mis Donaciones
                  <v-icon>mdi-playlist-check</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Realizar una Donación
                  <v-icon>mdi-hand-heart</v-icon>
                </v-tab>

                <v-tab href="#tab-3">
                  Mi Perfil
                  <v-icon>mdi-account-box</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item
                  :key="1"
                  :value="'tab-1'"
                >
                  <v-card flat>
                    <v-card-text>
                      <!-- Tabla de donaciones -->
                      <v-card class="elevation-0">
                        <v-data-table
                          :headers="data.headers"
                          :items="data.data"
                          :items-per-page="5"
                          :loading="data.load"
                          sort-by="donation"
                          class="elevation-0 border-solid"
                        >
                          <template v-slot:top>
                            <v-toolbar flat>
                              <v-toolbar-title class="text-h5">
                                Listado de Mis Donaciones
                              </v-toolbar-title>
                              <v-divider class="mx-4" inset vertical></v-divider>
                            </v-toolbar>
                          </template>
                          <!-- Actions -->
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  @click="donationDetail(item)"
                                  color="blue"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-eye
                                </v-icon>
                              </template>
                              <span>Ver Donación</span>
                            </v-tooltip>
                          </template>
                          <!-- Si no carga la data -->
                          <template v-slot:no-data>
                            <v-btn color="primary" @click="getDonations">
                              Reiniciar
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item
                  :key="2"
                  :value="'tab-2'"
                >
                  <v-card flat>
                    <v-card-text>
                      <Donation></Donation>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item
                  :key="3"
                  :value="'tab-3'"
                >
                  <v-card flat>
                    <v-card-text>
                      <Profile></Profile>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-container>
          <DonationDetail ref="mdlDetail" v-bind:donation="donationD"></DonationDetail>
        </v-card-text>
      </v-card>

      <v-card v-if="coordinator == true">
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Perfil de Coordinador</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-card>
              <!-- Tabs -->
              <v-tabs
                v-model="tab"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  Mis Grupos
                  <v-icon>mdi-playlist-check</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Mi Perfil
                  <v-icon>mdi-account-box</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item
                  :key="1"
                  :value="'tab-1'"
                >
                </v-tab-item>
                <v-tab-item
                  :key="2"
                  :value="'tab-2'"
                >
                  <v-card flat>
                    <v-card-text>
                      <Coordinator></Coordinator>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Snackbard />
  </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Coordinator from './Coordinator.vue';
import Donation from "./Donation";
import GroupDonation from './GroupDonation.vue';
import DonationDetail from "./DonationDetail";
import Profile from "./Profile";
import Snackbard from "@/components/Snackbar";

export default {
  name: "DonorModal",
  components: {
    Coordinator,
    Donation,
    DonationDetail,
    GroupDonation,
    Profile,
    Snackbard,
  },
  data: () => ({
    dialogDelete: false,
    dialog: false,
    donations: [],
    coordinator: false,
    donor: false,
    selectGroup: false,
    data: {
      load: true,
      loadGroup: false,
      headers: [
        { text: "Donación para", value: "donation_type" },
        { text: "Monto de Donación", value: "transaction_amount" },
        { text: "Fecha", value: "transaction_time_format" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      headersGroup: [
        { text: "Grupo", value: "group" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      data: [],
      dataGroups: [],
    },
    groupData: {},
    tab: null,
    donationD: {},
  }),
  mounted() {
    this.welcome();
    this.getDonations();
  },
  methods: {
    ...mapActions({
      getDonor: "Donor/getDonor",
      openSnackbar: "Snackbar/openSnackbar",
    }),

    welcome: function() {
      let token = undefined;
      //no existe token en vuex
      if (!this.$store.token || this.$store.token == "undefined") {
        token = sessionStorage.getItem("donor_frequent_token");
        if (token != undefined) {
          this.$store.commit("setToken", token);
        }
      } else {
        token = this.$store.token;
        this.$store.commit("setToken", token);
        sessionStorage.setItem("donor_frequent_token", token);
      }
      if (token) {
        this.$http.defaults.headers["Authorization"] = token;
        this.getDonor(this);
        if (this.user.id_donor_type == 1) {
          this.coordinator = true;
          this.donor = false;
        } else {
          this.donor = true;
          this.coordinator = false;
        }
      }
    },

    getDonations: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "tx/donor/" + this.user.id_donor)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    donationDetail(item) {
      this.donationD = {};
      this.donationD = item;
      this.modalDonation();  
    },

    modalDonation() {
      this.$refs.mdlDetail.changeModal();
    },


    donationGroup(item) {
      this.selectGroup = false
      this.groupData = {};
      this.selectGroup = true;
      this.groupData = item;
    },

    changeModal: function() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      token: "Donor/getToken",
      user: "Donor/getDonor",
    }),
  },
  watch: {
    user() {
      this.getDonations();
      this.getGroups();
      this.openSnackbar({
        type: "success",
        message: "Bienvenid@ " + this.user.name,
      });
      if (this.user.id_donor_type == 1) {
        this.coordinator = true;
        this.donor = false;
      } else {
        this.donor = true;
        this.coordinator = false;
      }
    },
  },
};
</script>

<style scoped>
.border-solid {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
