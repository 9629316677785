<template>
  <div class="footer white--text">
    <div class="footer--content">
      <v-container>
        <div class="content py-10">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <p class="text-h6 text-uppercase">
                Contacto
              </p>
              <hr class="divider" />
              <p class="mb-1">Dirección</p>
              <p>Avenida Aguilares 218, San Salvador, El Salvador</p>
              <p class="mb-1">Teléfono</p>
              <p>+503 2234-6000</p>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <p class="text-h6 text-uppercase">
                Mapa del Sitio
              </p>
              <hr class="divider" />
              <router-link to="/" class="text-decoration-none">
                <p class="white--text mb-1">Inicio</p>
              </router-link>
              <router-link to="/donation" class="text-decoration-none">
                <p class="white--text mb-1">Donación</p>
              </router-link>
              <router-link to="/about" class="text-decoration-none">
                <p class="white--text mb-1">Acerca de nosotros</p>
              </router-link>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <p class="text-h6 text-uppercase">
                Redes sociales
              </p>
              <hr class="divider" />
              <p>
                Siguenos para estar pendientes de nuestros últimos movimientos
              </p>
              <v-btn text icon color="white">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn text icon color="white">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn text icon color="white">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <div class="footer--copy">
      <v-container>
        <div class="content">
          <v-row>
            <p class="mb-0">
              Copyright © | 2020 Realizado por el Departamento de Informática
              del
              <a
                href="https://www.ricaldone.edu.sv/"
                target="_blank"
                class="white--text"
                >Instituto Técnico Ricaldone</a
              >
            </p>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.content {
  margin: 0 auto;
  max-width: 1200px;
}

.footer--content {
  background: #212121;
}

.footer--copy {
  background: #171717;
  font-size: 1.05rem;
  padding: 1.5rem 0;
}

hr.divider {
  background: white;
  border: 0px;
  height: 3px;
}

@media screen and (max-width: 960px) {
  .footer--content {
    padding: 0 2rem;
  }

  .footer--copy {
    padding: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .footer--content {
    padding: 0 1rem;
  }

  .footer--copy {
    font-size: 1rem;
  }
}
</style>
