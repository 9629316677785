import Vue from "vue";
import Vuex from "vuex";
import Snackbar from "./modules/Snackbar";
import Donor from "./modules/Donor";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ID_APP: 2,
    coreURL: "http://core.ricaldone.edu.sv/core",
    coreApiURL: "https://ricalapis.ricaldone.edu.sv/core_api/public/",
    baseApiURL: "https://ricalapis.ricaldone.edu.sv/donation_api/public/",
    token: "",
    auth: false,
    user: {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        charge: "",
        photo: "default.jpg",
      },
      userType: [],
      permissions: [],
      actionsPermissions: [],
      currentActions: [],
    },
  },
  getters: {
    //app
    getIdApp: (state) => state.ID_APP,
    getToken: (state) => state.token,
    //api
    getCoreURL: (state) => state.coreURL,
    getCoreApi: (state) => state.coreApiURL,
    getCoreImg: (state) => state.coreApiURL + "resources/images/",
    getApi: (state) => state.baseApiURL,
    getImg: (state) => state.baseApiURL + "resources/images/",
    //user
    getAuth: (state) => state.auth,
    getUser: (state) => state.user.user,
    getUserType: (state) => state.user.userType,
    getPermissions: (state) => state.user.permissions,
    getActionsPermissions: (state) => state.user.actionsPermissions,
    getCurrentActions: (state) => state.user.currentActions,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
    setUser(state, user) {
      state.user.user = user;
    },
    setUserType(state, userType) {
      state.user.userType = userType;
    },
    setPermissions(state, permissions) {
      state.user.permissions = permissions;
    },
    setActionsPermissions(state, actionsPermissions) {
      state.user.actionsPermissions = actionsPermissions;
    },
    setCurrentActions(state, currentActions) {
      state.user.currentActions = currentActions;
    },
  },
  actions: {
    getUser: async function({ commit, getters }, vm) {
      let api = getters.getCoreApi;
      const { data } = await vm.$http.get(api + "profile/my_data");
      if (data.response == false) {
        vm.$router.push("/");
      } else {
        commit("setAuth", true);
        commit("setUser", data);
      }
    },
    getUserType: async function({ commit, getters }, vm) {
      let api = getters.getCoreApi;
      const { data } = await vm.$http.get(api + "access/user_type");
      if (data.response != false) {
        let filterData = data.filter(
          (item) => item.id_application == getters.getIdApp
        );
        commit("setUserType", filterData[0]);
      }
    },
    getPermissions: async function({ commit, getters }, vm) {
      let api = getters.getCoreApi;
      const { data } = await vm.$http.get(api + "access/permission");
      if (data.response != false) {
        let filterData = data.filter(
          (item) => item.id_application == getters.getIdApp
        );
        commit("setPermissions", filterData);
      }
    },
    getActionsPermissions: async function({ commit, getters }, vm) {
      let api = getters.getCoreApi;
      const { data } = await vm.$http.get(api + "access/action_permission");
      if (data.response != false) {
        let filterData = data.filter(
          (item) => item.id_application == getters.getIdApp
        );
        commit("setActionsPermissions", filterData);
      }
    },
    getCurrentActions: function({ commit, dispatch, getters }, permission) {
      let currentActions = [];
      if (getters.getActionsPermissions.length != 0) {
        getters.getActionsPermissions.forEach((action) => {
          if (action.permission === permission) {
            currentActions.push(action.action);
          }
        });
        commit("setCurrentActions", currentActions);
      } else {
        window.setTimeout(() => {
          dispatch("getCurrentActions", permission);
        }, 250);
      }
    },
  },
  modules: {
    Donor,
    Snackbar,
  },
});
