<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-toolbar dark flat class="mb-5"
        >Historial de Donaciones de - {{ donor.name }}</v-toolbar
      >
      <v-card-text class="black--text">
        <v-row>
          <v-col class="pb-0">
            <p class="mb-0 mr-3 d-inline">
              <span class="subtitle-1 font-weight-bold">Nombre: </span
              >{{ donor.name }}
            </p>
            <p class="mb-0 d-inline">
              <span class="subtitle-1 font-weight-bold"
                >Correo electrónico: </span
              >{{ donor.email }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <p class="mb-0 mr-3 d-inline">
              <span class="subtitle-1 font-weight-bold">Teléfono: </span
              >{{ donor.phone }}
            </p>
            <p class="mb-0 d-inline">
              <span class="subtitle-1 font-weight-bold">Promoción: </span
              >{{ donor.promotion }}
            </p>
          </v-col>
        </v-row>
        <p class="mb-1">
          <span class="subtitle-1 font-weight-bold"
            >Realiza una donación cada: </span
          >{{ donor.frequent_donation }}
        </p>
        <p class="mb-1">
          <span class="subtitle-1 font-weight-bold"
            >Promesa de donación al año:
          </span>
          ${{ donor.goal_group }}
        </p>
        <v-data-table
          :headers="data.headers"
          :items="data.data"
          :items-per-page="5"
          :loading="data.load"
          sort-by="donation"
          class="elevation-0 border-solid"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="text-h5">
                Donaciones de {{ donor.name }}
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <!-- Column custom -->
          <template v-slot:[`item.transaction_amount`]="{ item }">
            $ {{ parseFloat(item.transaction_amount).toFixed(2) }}
          </template>
          <!-- Si no carga la data -->
          <template v-slot:no-data>
            <v-btn color="primary" @click="getDonationHistory">
              Recargar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="changeModal">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DonorDonations',
  data: () => ({
    dialog: false,
    data: {
      load: false,
      headers: [
        { text: 'Donación para', value: 'donation_type' },
        { text: 'Monto de Donación', value: 'transaction_amount' },
        { text: 'Año lectivo', value: 'year' },
        { text: 'Fecha de Donación', value: 'transaction_time_format' },
      ],
      data: [],
    },
  }),
  props: {
    donor: {},
  },
  mounted() {
    this.getDonationHistory();
  },
  methods: {
    getDonationHistory: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'tx/donor/' + this.donor.id_donor)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener datos',
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    changeModal: function() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
  },
  watch: {
    donor() {
      this.getDonationHistory();
    },
  },
};
</script>

<style></style>
